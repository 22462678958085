import { Typography, Stack, Link } from '@mui/material';

import { useIsMobile } from './useIsMobile';

function App() {
  const isMobile = useIsMobile();

  return (
    <Stack
      direction={'column'}
      spacing={1}
      justifyContent={'center'}
      alignItems={'center'}
      height={'100vh'}
    >
      <img
        src={`${window.location.origin}/GauDi_Logo.svg`}
        alt='logo'
        width={isMobile ? 200 : 400}
      />
      <Typography variant={isMobile ? 'h5' : 'h3'}>メンテナンス中</Typography>
      <Link
        href='https://forms.gle/nPNZGQsqSQawxnCr5'
        target='_blank'
        variant={isMobile ? 'body1' : 'h5'}
      >
        ご不明点はお問い合わせフォームにてご連絡ください
      </Link>
    </Stack>
  );
}

export default App;
